@import '../../../assets/stylesheets/base/colors';

.p-input-icon-left {
  img {
    position: absolute;
    top: 25%;
  }

  .search-submit {
    position: absolute;
    right: 0.25rem;
    top: 50%;
    transform: translate(0, -50%);
  }
}

.filter-panel-icon {
  background: transparent;
  border: 0;
  cursor: pointer;

  img {
    height: 14px;
    width: 12px;

    &.reset-icon {
      width: 15px;
    }

    &.view-icon {
      height: 17px;
      width: 17px;
    }
  }
}

.save-filter {
  &.disabled {
    cursor: auto;

    p {
      color: $gray3;
    }

    .save-icon {
      color: $gray3;
    }
  }
}
