// sass-lint:disable no-color-hex

// Primary
$takedaRed: #450a0a;
$takedaRed20: #f3efef;
$takedaRedHover: #721313;

// Neutral
$black: #000000;
$white: #ffffff;

// RAG
$ragGreen: #649832;
$ragGreen120: $ragGreen;
$ragGreen60: #90b17a;
$ragGreen40: #b2c7a5;
$ragGreen20: #cfdbc8;

$ragRed: #ea5532;
$ragRed120: #d14c2c;
$ragRed60: #ee897a;
$ragRed40: #ee897a;
$ragRed20: #f6ccc8;
$todayColumnRed: rgba($ragRed20, 0.3);

$ragYellow: #eba902;
$ragYellow120: #d29701;
$ragYellow60: #efbd72;
$ragYellow40: #f3cfa1;
$ragYellow20: #f3cfa1;
$ragAmber20: #f7e0c5;

// Data visualization
$darkAqua: #0a8b9e;
$aqua: #0c9cb1;
$aqua80: #4191d2;
$aqua60: #72b4c3;
$aqua40: #a1c9d3;
$aqua20: #c5dce3;
$aqua10: #e3ebf7;

$darkPurple: #7a70b1;
$purple: #8c81cb;
$purple60: #a9a2d6;
$purple40: #c2bde1;
$purple20: #d8d5eb;

$gray1: #34373f;
$gray2: #63666e;
$gray3: #878b97;
$gray4: #dddfe3;
$gray5: #edf2f4;
$gray6: #f2f6f7;
$gray7: #bebebe;

$newBackground: #f9fafa;
$suvodaBannerBackground: #fceecd;
$bannerHyperlink: #0036ce;
$emailLink: #23a6d9;

// Gen AI Assistant
$chatBackground: #2d2d2d;
$chatWindow1: #000000;
$chatWindow2: #292a30;
$chatWindowShadowColor: rgba(0, 0, 0, 0.25);

$chatTextPrimary: #ffffff;
$chatTextSecondary: #1e1e1e;
$chatTextInverse: #34373f;

$chatHover: #24b8e1;
$chatIcon: #dddfe3;
$chatInputBackground: #878b97;
$chatAIIcon: #084254;
$chatPromptSelected: #252424;
$promptFontColor: #e1e1e1;
$chatPromptBorder: #3b3b3b;

$chatErrorPrimary: rgba(237, 0, 42, 0.1);
$chatErrorSecondary: #ed002a;

$chatJourneyBlurOverlay: rgba(128, 128, 128, 0.5);
$chatJourneyHighlightBorder: #ffe100;
$chatJourneyHighlightRadiant: rgba(255, 227, 16, 0.7);
$chatJourneyHighlightBackground1: rgba(251, 254, 255, 0.7);
$chatJourneyHighlightBackground2: rgba(255, 225, 0, 0.24);
$chatJourneyDialogBackground1: #34373f;
$chatJourneyDialogBackground2: #121722;

$chatScrollThumb: #f9f9f9;
$chatScrollTrack: #101010;

$chatGuidelinesBorder: #5d5d5d;
